import React from 'react'
import logo from '../../assets/images/logo.png';
import { Loading } from '../../components/Generic';
//import { HeaderMenu } from '../../components/Generic';

function PrivacyPolicy() {
    return (
      <div id="wrapper" className="register">
            <header id="header">
                <div className="container">
                    <div className="inner-header">
                        <div className="logo">
                            <a href="/dashboard"><img src={logo} alt="Logo" /></a>
                        </div>
                        {/* <HeaderMenu signup /> */}
                    </div>
                </div>
               
            </header>

           <section id="section">
                    <div className="container">
        <h1><strong>Privacy Policy</strong></h1>
  <br/>
  <br/>
        <p><strong>
          EnVue Health, LLC ("EnVue Health", "we", "us", or "our") operates the EnVue Health® mobile application (the "App") and the website www.envuehealth.com (the "Website"). This Privacy Policy outlines how we collect, use, and protect your personal information when you use our services.
        </strong></p>
  
        <h2><strong>Information We Collect</strong></h2>
  <br/>
        <h3><strong>Contact Information</strong></h3>
  <br/>
        <p><strong>
          When you use our App, we may collect personal information such as your name, email address, and any other contact details you provide. This information is used to facilitate communication and interaction within the EnVue Health network.
        </strong></p>
  <br/>
        <h3><strong>Profile Information</strong></h3><br/>
        <p><strong>
          If you are a EnVue Health professional, we may collect information related to your professional profile, including your qualifications, specializations, and contact details. This information helps patients locate and connect with healthcare professionals on our platform.
        </strong></p>
        <br/>
        <h3><strong>Usage Data</strong></h3><br/>
        <p><strong>
          We collect information on how you access and use the App and Website ("Usage Data"). This may include your device's Internet Protocol (IP) address, browser type, browser version, pages of our App or Website that you visit, the time and date of your visit, the time spent on those pages, and other diagnostic data. This data helps us analyze and improve the performance and functionality of our services.
        </strong></p>
  <br/>
        <h2><strong>How We Use Your Information</strong></h2>
  <br/>
        <p><strong>
          We use the information we collect for various purposes, including but not limited to:
          Providing and maintaining our services,
          Communicating with you,
          Improving our services and user experience,
          Analyzing trends and user behavior,
          Personalizing your experience, and
          Ensuring the security of our services.
        </strong></p>
        <br/>
        <h2><strong>Information Sharing and Disclosure</strong></h2>
  <br/>
        <p><strong>
          We may share your personal information in the following circumstances:
          With your consent,
          To comply with legal obligations,
          To protect and defend our rights or property,
          To prevent or investigate possible wrongdoing, and
          In connection with a business transaction, such as a merger or acquisition.
        </strong></p>
        <br/>
        <h2><strong>Data Security</strong></h2>
  <br/>
        <p><strong>
          We are committed to protecting the security of your personal information and use industry-standard security measures to safeguard it against unauthorized access, disclosure, alteration, or destruction.
        </strong></p>
        <br />
        <p><strong>
          By using our services, you consent to the collection, use, and sharing of your information as described in this Privacy Policy. We reserve the right to update or modify this Privacy Policy at any time. Please review it periodically for changes.
        </strong></p>
  
        <p><strong>Thank you for choosing EnVue Health. We appreciate your commitment to better health outcomes for all patients.
          </strong></p>
  <br/>
  <div className='contactUs'><h2><strong>Contact Us & Support</strong></h2>
  <br/>
        <p><strong>
          If you have any questions or concerns, please contact us at lecresha@melanatedhealthcare.app.
        </strong></p>
        <br/>
        <p><strong>
          follow us on LinkedIn <a href='https://www.linkedin.com/in/melanated-healthcare%C2%AE-570093201/'>EnVue Health on LinkedIn</a></strong></p>
  </div>
         
        
      </div>
         </section>
            <Loading />
        </div>
    );
  }
  
  export default PrivacyPolicy;
