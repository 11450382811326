import React, { useState, useEffect } from 'react'
import edit_icon from "../../assets/images/edit_icon.png"
import profile_pic from "../../assets/images/logo.png";
import pass_key from "../../assets/images/pass_key.png"
import address_icon from "../../assets/images/address_icon.png"
import msg_icon from "../../assets/images/msg_icon.png"
import './style.css'
import { UserModal } from '../../components/Generic/userModals/UserModal'
import NotificationsTab from './NotificationsTab'
import {CustomSweetAlert, PhotoUploadModal } from '../../components/Generic';
import MyComments from './MyComments'
import { withFirebase } from '../../services'
import { FileUploadWithURL } from '../../common';

 const UserProfile = (props) => {


    const { firebase } = props;
    const [isSaving, setIsSaving] = useState(false)
    const reload=()=>window.location.reload();
    const [currentTab, setCurrentTab] = useState("your comments")
    const [modalShow, setModalShow] = useState(false)
    const [modalName, setModalName] = useState("")
    const [profilePhoto, setProfilePhoto] = useState(null)
    const [userData, setUserData] = useState(null)

    const [SweetAlert, setSweetAlert] = useState({
        show: false,
        type: "info",
        title: "",
        onConfirm: () => { setSweetAlert({ ...setSweetAlert, show: false }) },
        onCancel: () => { setSweetAlert({ ...setSweetAlert, show: false }) }
    });
    const [PhotoModal, setPhotoModal] = useState(false)

    useEffect(() => {
       getUsersData()
    }, [])
    const email = "cyberquantumservices@gmail.com";
    const subject = "Delete account request";
   const getUsersData = async () => {
       const uid = firebase.auth.currentUser.uid

       const result = await firebase.db.collection("users").doc(uid);

       result.onSnapshot((doc) => {
           const data = doc.data()
           setProfilePhoto(data.profilePhoto)
           setUserData(data);
       });

   }
  
    const changeTab = (name) => {
        setCurrentTab(name)
    }

    const handleProfileChange = (e) => {

       setProfilePhoto(e.currentTarget.files[0])
       setPhotoModal(true);
    }

    const updateUserProfile = async (url) => {
        const userId = firebase.auth.currentUser.uid
        try {
            let dataTobeSaved = { ...userData }
            dataTobeSaved.profilePhoto = url
            let ref = firebase.db.collection("users").doc(userId);

            await ref.set(dataTobeSaved, { merge: true }).then(() => {
               
                setSweetAlert({ 
                    ...SweetAlert, 
                     show: true,
                     title: "Profile Successfully Updated.", 
                     type: "success", 
                     onConfirm: () => {
                        setSweetAlert({...SweetAlert, show: false})
                        setModalShow(false)
                     } })
            }).catch((error) => {
                setIsSaving(false)
                console.log(error)
            });
        } catch (error) {
            console.log(error)
            setIsSaving(false)
        }

    }


    const PhotoToSave = (dataUrl) => {

      let Data = FileUploadWithURL({ img: dataUrl, path: `profileImage`, firebase: firebase, name: userData.name });
                Data.then(url => {
                    updateUserProfile(url)
                    setProfilePhoto(dataUrl)
                    setIsSaving(false)
                    setPhotoModal(false);
                }).catch(e => {
                    setPhotoModal(false);
                    setIsSaving(false)
                })
    }

    const onPasswordReset = async () => {
        await firebase.auth.sendPasswordResetEmail(userData.email).then(function () {
            setSweetAlert({
                show: true,
                type: "success",
                message: "A reset password link has been sent to your email",
                onConfirm: ()=> {setSweetAlert({...setSweetAlert,show:false})},
                onCancel: ()=> {setSweetAlert({...setSweetAlert,show:false})}
            })
        }).catch(function (error) {
            console.log(error);
            setSweetAlert({
                show: true,
                type: "warning",
                message: error.message,
                onConfirm: ()=> {setSweetAlert({...setSweetAlert,show:false})},
                onCancel: ()=> {setSweetAlert({...setSweetAlert,show:false})}
            })
        });
    }


    return (

        <section>
            <div className="profile-container">

                <div className="header-data">
                    <div className="mid-data"> 
                        <div className="profile">
                            
                            <img  src={profilePhoto ? profilePhoto : profile_pic} alt="User" />
                 
                            <div className="change-profile">
                                <button className="btn">+</button>
                            <input type="file" accept='image/*' onChange={(e) => handleProfileChange(e)} />
                            </div>
                        </div>
                        <div className="profile-right">
                            <div className="name-container">
                                <span>{userData?.name}</span>
                                <img onClick={() => {
                                    setModalName("username")
                                    setModalShow(true)
                                    }} src={edit_icon} alt="edit-name" />
                            </div>
                            <div className="email">
                                <div className="back-shade"><img src={msg_icon} alt="msg" /></div>
                                <p className="mail-id">{userData?.email}</p>
                            </div>
                            <span onClick={() => {
                                 setModalName("email")
                                 setModalShow(true)}} className="change-mail">Change details</span>
                                 
    

    <div ><a title='To delete account mail to cyberquantumservices@gmail.com with your account id' className="change-mail" href={`mailto:${email}?subject=${encodeURIComponent(subject) || ''}&body=${encodeURIComponent("") || ''}`}>Delete Account</a></div>
      
                        </div> 
                        <div className="right-data"> 
                            <div className="address">
                                <div className="location">
                                    <div className="back-shade"><img src={address_icon} alt="location" /></div>
                                    <span>{userData?.location ? userData?.location : "N/A" }</span>
                                </div>
                                <span onClick={() => {
                                     setModalName("location")
                                     setModalShow(true)}} className="change-location">Change location</span>
                            </div>
                            <div className="password">
                                    <div className="back-shade"><img src={pass_key} alt="pass" /></div>
                                    <span onClick={onPasswordReset}>Change password</span>
                                </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="profile-list-inner">
                <div className="container">
                    <div className="tabs-container">
                        <span onClick={() => changeTab("your comments")} className={currentTab === "your comments" ? 'active' : "span"}>Your Comments</span>
                        <span onClick={() => changeTab("notifications")} className={currentTab === "notifications" ? 'active' : "span"}>Notifications</span>
                    </div>
                    { currentTab === "your comments" ? <MyComments /> :
                    <NotificationsTab userData={userData} />
                    }
                </div>
            </div>
              <UserModal show={modalShow} setModalShow={setModalShow} name={modalName} userData={userData} SweetAlert={SweetAlert} setSweetAlert={setSweetAlert} reload={reload} onHide={reload} />
              <CustomSweetAlert {...SweetAlert} />
              <PhotoUploadModal show={PhotoModal} onHide={() => setPhotoModal(false)} img={profilePhoto} onSave={PhotoToSave} isSaving={isSaving} setIsSaving={setIsSaving} />

        </section>
    )
}


export default withFirebase(UserProfile)